<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-file-gallery
        class="pa-3"
        :images="ficherosGaleria"
      />
      <b10-fab-button
        v-if="formattedItem.estado === PARTE_NOVEDAD.estados.pendiente"
        @click="clickAddNovedad"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import { get, sync } from 'vuex-pathify'
import Data from './ParteNovedadViewData'
import { toDate, isEqual } from '@/utils/date'
import { PARTE_NOVEDAD } from '@/utils/consts'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      toolbarOptions: {
        finalizar: {
          title: 'Finalizar',
          visible: true,
          icon: 'finalizar'
        },
        modificar: {
          title: 'Modificar',
          visible: true,
          icon: 'edit'
        },
        eliminar: {
          title: 'Eliminar',
          icon: 'delete',
          visible: true
        },
      },
      moreInfoRows: [
        { name: 'fparte', filter: this.$options.filters.shortDateTime },
        { name: 'estado_descripcion', label: 'Estado' },
        'identificador',
        'puesto_servicio_descripcion',
        'puesto_servicio_direccion',
        'observaciones',
      ],
      showingDialogs: {
        moreInfo: false,
      },
      ficherosGaleria: [],
      PARTE_NOVEDAD,
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    presenciIdparteNovedad: sync('presencia/idparteNovedad'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idparte_novedad
        if (isEqual(item.fparte, toDate(item.fparte))) {
          item.fechaParte = this.$options.filters.shortDate(item.fparte)
        } else {
          item.fechaParte = this.$options.filters.shortDateTime(item.fparte)
        }
        item.title = item.fechaParte
        item.subtitle = [item.puesto_servicio_descripcion]
        if (item.observaciones) {
          item.subtitle.push(item.observaciones)
        }
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        if (item.estado === PARTE_NOVEDAD.estados.pendiente) {
          item.badge = 'Pendiente'
          item.badgeColor = PARTE_NOVEDAD.colores.pendiente
        } else {
          item.badge = 'Finalizado'
          item.badgeColor = PARTE_NOVEDAD.colores.finalizado
        }
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = `Parte de novedad Nº${this.routeParams.idparte_novedad}`
      await this.loadItem()
      this.toolbarOptions.finalizar.visible = this.item.dataset.estado === PARTE_NOVEDAD.estados.pendiente
      this.toolbarOptions.modificar.visible = this.item.dataset.estado === PARTE_NOVEDAD.estados.pendiente
      this.toolbarOptions.eliminar.visible = this.item.dataset.estado === PARTE_NOVEDAD.estados.pendiente
      // adjuntos de todas las novedades
      const [datasetFicheros] = await Data.selectFicheroParteNovedad(
        this, this.routeParams.idparte_novedad,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      this.ficherosGaleria = datasetFicheros
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectParteNovedad(this, this.routeParams.idparte_novedad, this.usuarioIdvigilante)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const detailNovedades = this.addDetail(
        'novedades', 'Novedades', 'Novedades e incidencias registradas', 'novedad'
      )
      const [datasetNovedades] = await Data.selectLparteNovedadCount(
        this, this.routeParams.idparte_novedad, this.usuarioIdvigilante
      )
      detailNovedades.badge = datasetNovedades.count || 0
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.modificar) {
        this.$appRouter.push({
          name: 'partesnovedad__parte-novedad-edit',
          params: {
            idparte_novedad: this.routeParams.idparte_novedad
          }
        })
      } else if (option === this.toolbarOptions.eliminar) {
        this.clickEliminar()
      } else if (option === this.toolbarOptions.finalizar) {
        this.clickFinalizar()
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'novedades') {
        this.$appRouter.push({
          name: 'partesnovedad__lparte-novedad-list',
          params: {
            idparte_novedad: this.routeParams.idparte_novedad
          }
        })
      }
    },
    async clickEliminar () {
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar el parte de novedad Nº${this.routeParams.idparte_novedad}?`
      )
      if (res) {
        await Data.deleteParteNovedad(this, this.routeParams.idparte_novedad)
        // comparación por igualdad abstracta == porque idparte_novedad puede ser string o number
        if (this.presenciIdparteNovedad == this.routeParams.idparte_novedad) {
          this.presenciIdparteNovedad = null
        }
        await this.$dirty.deleted(this.$dirty.ENTITIES.remote.parteNovedad, this.routeParams.idparte_novedad)
        this.$alert.showSnackbarSuccess('Parte de novedad eliminado')
        this.$appRouter.go(-1)
      }
    },
    async clickFinalizar () {
      const res = await this.$alert.showConfirm(
        `¿Deseas finalizar el parte de novedad Nº${this.routeParams.idparte_novedad}?`
      )
      if (res) {
        await Data.finParteNovedad(this, this.routeParams.idparte_novedad)
        // comparación por igualdad abstracta == porque idparte_novedad puede ser string o number
        if (this.presenciIdparteNovedad == this.routeParams.idparte_novedad) {
          this.presenciIdparteNovedad = null
        }
        await this.$dirty.modified(this.$dirty.ENTITIES.remote.parteNovedad, this.routeParams.idparte_novedad)
        this.$alert.showSnackbarSuccess('Parte de novedad finalizado')
        this.$appRouter.go(-1)
      }
    },
    async clickAddNovedad () {
      this.$appRouter.push({
        name: 'partesnovedad__lparte-novedad-add',
        params: {
          idparte_novedad: this.item.dataset.idparte_novedad
        }
      })
    }
  }
}
</script>
