import { APIFilter } from '@/utils/api'
import { PARTE_NOVEDAD, TABLA } from '@/utils/consts'

export default {
  async selectParteNovedad (Vue, idparteNovedad, idvigilante) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idparte_novedad', idparteNovedad)
      .addExact('idvigilante', idvigilante)
    const resp = await Vue.$api.call('parteNovedad.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectLparteNovedadCount (Vue, idparteNovedad, idvigilante) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idparte_novedad', idparteNovedad)
      .addExact('idvigilante', idvigilante)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('lparteNovedad.select', {
      filter: apiFilter, wrapper: 'count'
    })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectFicheroParteNovedad (Vue, idparteNovedad, esTecnico, esComercial, esVigilante) {
    const apiFilter = Vue.$online.fichero.buildApiFilterVisualizacion(idparteNovedad, TABLA.parte_novedad.idtabla, esTecnico, esComercial, esVigilante)
    const resp = await Vue.$api.call('fichero.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  deleteParteNovedad (Vue, idparteNovedad) {
    Vue.$api.call('parteNovedad.delete', {
      idparte_novedad: idparteNovedad
    })
  },
  async finParteNovedad (Vue, idparteNovedad) {
    const resp = await Vue.$api.call(
      'parteNovedad.update',
      {
        values: {
          idparte_novedad: idparteNovedad,
          ffin: new Date(),
          estado: PARTE_NOVEDAD.estados.finalizado,
        },
      }
    )
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
}
